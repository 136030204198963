<template>
  <div>
    <div
      v-if="!minimal && !!(this.$slots.actions || [])[0]"
      class="table__additional-actions"
    >
      <b-card
        v-if="standalone"
        body-class="p-2"
      >
        <slot name="actions" />
      </b-card>
      <div
        v-else
        class="mb-2 mx-n3 bg-gray-100 px-3 py-2 mt-n3"
      >
        <slot name="actions" />
      </div>
    </div>
    <b-card
      v-if="standalone"
      class="border-0"
      body-class="pt-0 overflow-hidden"
    >
      <div
        v-if="!minimal && !noFilters"
      >
        <table-filters
          :toggle-buttons="toggleButtonsInner"
          :manage-filter="manageFilter"
          :update-search-string="updateSearchString"
          :search-action="customSearch"
          :no-search="noSearch"
        >
          <template #filters>
            <slot name="filters" />
          </template>
        </table-filters>
      </div>
      <loader-info v-if="isLoading" />
      <div v-else>
        <div  v-if="listLength > 0">
          <b-table-simple striped responsive>
            <slot name="headers">
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="(header, index) in tableHeader"
                    :key="index+'-'+header.content.toString()"
                    :class="header.classes"
                  >
                    {{header.content}}
                  </b-th>
                </b-tr>
              </b-thead>
            </slot>
            <b-tbody>
              <slot name="body" />
            </b-tbody>
          </b-table-simple>
        </div>
        <div v-else>
          <slot name="noResults">
            Nessun risultato trovato
          </slot>
        </div>
      </div>
      <div
        v-if="!minimal"
        class="d-flex"
      >
        <table-pagination
          :data-size="dataSize"
          :page-sizes="pageSizes"
          :change-page-size="changePageSize"
          :total-elements="rows"
          :fetch-data="fetchData"
          :pager="pager"
        />
      </div>
    </b-card>
    <div v-else>
      <div
        v-if="!minimal && !noFilters"
      >
        <table-filters
          :toggle-buttons="toggleButtonsInner"
          :manage-filter="manageFilter"
          :update-search-string="updateSearchString"
          :search-action="customSearch"
          :no-search="noSearch"
        >
          <template #filters>
            <slot name="filters" />
          </template>
        </table-filters>
      </div>
      <loader-info v-if="isLoading" />
      <div v-else>
        <div  v-if="listLength > 0">
          <b-table-simple striped responsive>
            <slot name="headers">
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="(header, index) in tableHeader"
                    :key="index+'-'+header.content.toString()"
                    :class="header.classes"
                  >
                    {{header.content}}
                  </b-th>
                </b-tr>
              </b-thead>
            </slot>
            <b-tbody>
              <slot name="body" />
            </b-tbody>
          </b-table-simple>
        </div>
        <div v-else>
          <slot name="noResults">
            Nessun risultato trovato
          </slot>
        </div>
      </div>
      <div
        v-if="!minimal"
        class="d-flex"
      >
        <table-pagination
          :data-size="dataSize"
          :page-sizes="pageSizes"
          :change-page-size="changePageSize"
          :total-elements="rows"
          :fetch-data="fetchData"
          :pager="pager"
        />
      </div>
    </div>
  </div>
</template>

<script>

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const TableFilters = () => import('@/components/tableFilters/TableFilters.vue');
const TablePagination = () => import('@/components/tablePagination/TablePagination.vue');

export default {
  name: 'TableWrapper',
  components: {
    LoaderInfo,
    TablePagination,
    TableFilters,
  },
  props: {
    dataSize: Number,
    minimal: Boolean, // no advanced features
    searchApi: String,
    hasPageSizes: Boolean,
    pageSizes: Array,
    tableHeader: Array,
    toggleButtons: Array,
    noFilters: Boolean,
    entityType: String,
    entityId: String,
    customSearch: Function,
    changePageSize: Function,
    updateSearchString: Function,
    fetchData: Function,
    manageFilter: Function,
    isLoading: Boolean,
    rows: Number,
    pager: Object,
    listLength: Number,
    standalone: Boolean, // adds padded card around
    noSearch: Boolean,
  },
  data() {
    return {
      toggleButtonsInner: this.toggleButtons || [],
    };
  },
  computed: {},
};
</script>

<style scoped>

</style>
